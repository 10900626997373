<template>
  <div class="password-reset">
    <div class="password-reset__container">
      <form class="password-reset__form" action="#" method="post">
        <span class="password-reset__caption">
          Восстановление доступа
        </span>
        <span class="password-reset__error" v-show="hasError" v-html="error"></span>
        <div class="password-reset__input-group">
          <input
              class="password-reset__input"
              type="email"
              placeholder="E-mail"
              v-model="email"
              :class="{ 'password-reset__input_error' : hasError}"
          >
          <input
              class="password-reset__input"
              type="password"
              placeholder="Новый пароль"
              v-model="password"
              :class="{ 'password-reset__input_error' : hasError}"
          >
          <input
              class="password-reset__input"
              type="password"
              placeholder="Повторите пароль"
              v-model="passwordConfirmation"
              :class="{ 'password-reset__input_error' : hasError}"
          >
        </div>
        <app-button class="password-reset__button" button-style="button_action" is-submit @click.prevent="reset">
          Восстановить доступ
        </app-button>
        <router-link class="password-reset__link" :to="{ name: 'Login' }">
          Войти
        </router-link>
      </form>
      <footer class="password-reset__footer">
        &#169; 2022-{{ new Date().getFullYear() }} ГК “Триумф”
      </footer>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton'
import { reset } from '@/api/auth'
import { isError } from '@/utils/request'

export default {
  name: 'PasswordReset',
  components: {
    AppButton
  },
  data() {
    return {
      token: undefined,
      email: '',
      password: '',
      passwordConfirmation: '',
      error: '',
      hasError: false
    }
  },
  methods: {
    async reset() {
      if (this.token === undefined) {
        this.error = 'Токен не обнаружен'
        this.hasError = true
      }

      if (this.password !== this.passwordConfirmation) {
        this.error = 'Допущена ошибка при вводе паролей'
        this.hasError = true
      }

      this.error = ''
      this.hasError = false

      const response = await reset(this.token, this.email, this.password)

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              this.error += `${error}<br>`
            }
          }
        } else {
          this.error = response.data.meta.message
        }

        this.hasError = true

        return
      }

      this.$notify({
        type: 'success',
        text: response.data.meta.message
      });

      setTimeout(() => {
        this.$router.push({ name: 'Login' })
      }, 3000)
    }
  },
  created() {
    const params = new URLSearchParams(window.location.search)

    if (params.has('token')) {
      this.token = params.get('token')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.password-reset {
  min-height: 100vh;
  padding: 50px 20px;
}

.password-reset__form {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #eaedf4;
  border-radius: $border-radius;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.03);
}

.password-reset__caption {
  display: inline-block;
  width: 100%;
  margin-bottom: 22px;
  font-size: 14px;
  text-align: center;
}

.password-reset__error {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 13px;
  color: #d31a1a;
}

.password-reset__input {
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  padding-left: 20px;
  font-size: 13px;
  background-color: #fcfdfe;
  border: 1px solid #eaedf4;
  border-radius: $border-radius;

  &:last-child {
     margin-bottom: 20px;
   }

  &:focus {
     outline: none;
   }

  &_error {
     border: 1px solid #D31A1A;
   }
}

.password-reset__button {
  width: 100%;
  margin-bottom: 20px;
}

.password-reset__link {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #555;
}

.password-reset__footer {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #555;
}

@media screen and (min-width: 576px) {
  .password-reset {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .password-reset__container {
    width: 440px;
  }

  .password-reset__form {
    margin-bottom: 23px;
    padding: 54px 60px;
  }

  .password-reset__caption {
    font-size: 17px;
  }

  .password-reset__input {
    margin-bottom: 30px;
    font-size: 14px;

&:last-child {
   margin-bottom: 40px;
 }
}

.password-reset__button {
  width: 100%;
  margin-bottom: 23px;
}

.password-reset__link {
  font-size: 16px;
}

.password-reset__footer {
  font-size: 16px;
}
}
</style>